import {
  ArrowForwardRounded,
  OpenInNewRounded,
} from '@mui/icons-material'
import type { SlideProps } from '@mui/material'
import {
  Box,
  Card,
  Dialog,
  Fade,
  Slide, Stack,
  Typography,
  useTheme,
} from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'
import { FlexCol, FlexGrowCol } from '@xylabs/react-flexbox'
import { asLinkHrefOrToProps, LinkEx } from '@xylabs/react-link'
import * as React from 'react'

import { SlideInArrow } from '../../common/index.ts'
import type { DropdownSectionDataProps, SectionProps } from '../Dropdown/index.ts'
import { SectionAppBar } from './SectionAppBar.tsx'

export const MobileNavSectionDialogRender: React.FC<SectionProps> = ({
  items,
  title,
}) => {
  const theme = useTheme()

  return (
    <Stack gap={3} flexDirection="column" alignItems="flex-start">
      <FlexCol alignItems="flex-start">
        {title ? <Typography fontWeight={theme.typography.fontWeightMedium} paddingBottom={1} gutterBottom variant="h6">{title}</Typography> : null}
        <Stack gap={2} flexDirection="column">
          {items.map((item, index) => {
            const [hover, setHover] = React.useState(false)
            return (
              <Slide key={index} in timeout={index === 0 ? 600 : (index + 1) * 400} direction="right">
                <LinkEx
                  {...asLinkHrefOrToProps({ to: item.to, href: item.href })}
                  color={hover ? 'secondary' : 'inherit'}
                  onTouchStart={() => setHover(true)}
                  onTouchEnd={() => setHover(false)}
                >
                  <Stack flexDirection="row" gap={0.5} alignItems="center">
                    <Typography variant="body1" color={hover ? theme.palette.secondary.main : 'inherit'}>{item.linkText}</Typography>
                    {item.to ? <ArrowForwardRounded fontSize="small" /> : <OpenInNewRounded fontSize="small" />}
                  </Stack>
                </LinkEx>
              </Slide>
            )
          })}
        </Stack>
      </FlexCol>
    </Stack>
  )
}
