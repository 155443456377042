import { LaunchRounded } from '@mui/icons-material'
import {
  Box,
  Container, Grid, Stack, Typography, useTheme,
} from '@mui/material'
import { asButtonHrefOrToProps } from '@xylabs/react-button'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import type { ReactNode } from 'react'
import React from 'react'

import { AppBarOffset } from '../AppBarOffset.tsx'
import { HeroImages } from '../img/index.ts'
import { ButtonSection } from './ButtonSection.tsx'

export interface BasicHeroProps extends FlexBoxProps {
  backgroundImage?: string
  button1Href?: string
  button1Text?: string
  button1To?: string
  button2Href?: string
  button2Text?: string
  button2To?: string
  desc: string
  extras?: ReactNode
  gradientTitle?: string
  heroComponent?: ReactNode
  heroImage?: string
  subLinkSection?: ReactNode
  sx?: Record<string, string>
  title: string
  title2?: string
}

export const XyoBasicHero: React.FC<BasicHeroProps> = ({
  backgroundImage = HeroImages.earthLowResLowOpacityHalf,
  title,
  desc,
  heroComponent,
  heroImage,
  button1Text,
  button2Text,
  button2To,
  button1To,
  button2Href,
  button1Href,
  extras,
  subLinkSection,
  sx,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Box>
      <FlexGrowCol
        position="relative"
        sx={{
          backgroundImage: { md: `url(${backgroundImage})`, xs: 'none' },
          backgroundPosition: {
            lg: 'center left', md: 'center left', xs: 'center left',
          },
          backgroundSize: { md: 'cover', xs: '100%' },
          justifyContent: 'flex-start',
          overflow: 'hidden',
          paddingTop: { md: theme.spacing(0), xs: theme.spacing(2) },
          ...sx,
        }}
        style={{ backgroundRepeat: 'no-repeat' }}
        {...props}
      >
        <AppBarOffset />
        <Container maxWidth="lg" sx={{ overflow: 'visible' }}>
          <Grid
            container
            sx={{
              overflow: 'visible',
              alignItems: { xs: 'stretch' },
              justifyContent: { md: 'flex-start', xs: 'center' },
              minHeight: { xs: '400px' },
            }}
          >
            <Grid item xs={12} sm={8} md={6}>
              <FlexGrowCol
                paddingY={3}
                sx={{
                  alignItems: { md: 'flex-start', xs: 'center' },
                  marginTop: { md: 3, xs: 2 },
                }}
              >
                <Typography variant="h1" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {title
                    ? <span>{`${title} `}</span>
                    : null}
                </Typography>
                <Typography variant="body1" component="h2" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {desc}
                </Typography>
                <Stack sx={{ flexDirection: { md: 'row', xs: 'column' } }} gap={1} marginTop={1}>
                  <ButtonSection
                    endIcon={button1Href?.length !== undefined && button1Href?.length > 0 ? <LaunchRounded /> : undefined}
                    {...asButtonHrefOrToProps({ href: button1Href, to: button1To })}
                    buttonText={button1Text}
                    onClick={() => {
                      if (button1To?.startsWith('#')) {
                        const section = document.querySelector(button1To)
                        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    }}
                  />
                  <ButtonSection
                    endIcon={button2Href?.length !== undefined && button2Href?.length > 0 ? <LaunchRounded /> : undefined}
                    variant="outlined"
                    {...asButtonHrefOrToProps({ href: button2Href, to: button2To })}
                    buttonText={button2Text}
                    onClick={() => {
                      if (button2To?.startsWith('#')) {
                        const section = document.querySelector(button2To)
                        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                      }
                    }}
                  />
                </Stack>
                {subLinkSection}
                {extras}
              </FlexGrowCol>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              alignItems="center"
            >
              <FlexGrowCol
                alignItems="center"
                height="100%"
              >
                {heroComponent}
              </FlexGrowCol>
            </Grid>
          </Grid>
          {heroImage
            ? (
                <FlexGrowCol
                  position="absolute"
                  right={-70}
                  bottom={-20}
                  alignItems="center"
                  sx={{
                    backgroundImage: { md: `url(${heroImage})`, xs: 'none' },
                    backgroundPosition: { lg: 'bottom left', md: 'bottom left' },
                    backgroundSize: { xs: '120%', lg: '120%' },
                    backgroundRepeat: 'no-repeat',
                    overflow: 'visible',
                    width: '45vw',
                    minHeight: {
                      md: '500px',
                      sm: '500px',
                      xs: '400px',
                    },
                    display: { md: 'flex', xs: 'none' },
                  }}
                >
                </FlexGrowCol>
              )
            : null}
        </Container>
      </FlexGrowCol>
    </Box>
  )
}
